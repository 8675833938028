import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "flex-col",
  style: {
    paddingRight: '10px'
  }
};
var _hoisted_2 = {
  class: "flex-col"
};
var _hoisted_3 = {
  notranslate: ""
};
var _hoisted_4 = {
  notranslate: ""
};
var _hoisted_5 = {
  notranslate: ""
};
var _hoisted_6 = {
  notranslate: ""
};
var _hoisted_7 = {
  notranslate: ""
};
var _hoisted_8 = {
  notranslate: ""
};
import { useRouter } from 'vue-router';
import { UserFilled, Edit } from '@element-plus/icons-vue';
export default {
  props: {
    ready: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var router = useRouter();
    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      var _component_AtuCtaBanner = _resolveComponent("AtuCtaBanner");

      var _component_TrialBanner = _resolveComponent("TrialBanner");

      var _component_SubStatusBanner = _resolveComponent("SubStatusBanner");

      var _component_SimpleCard = _resolveComponent("SimpleCard");

      return _openBlock(), _createBlock(_component_el_row, {
        align: "middle",
        justify: "center"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, null, {
            default: _withCtx(function () {
              return [_createVNode(_component_SimpleCard, {
                style: {
                  padding: '20px'
                }
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_row, {
                    align: "middle",
                    justify: "space-between",
                    class: "std-padding_line-md"
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_el_col, {
                        span: 18
                      }, {
                        default: _withCtx(function () {
                          return [_createVNode(_component_el_row, {
                            align: "middle",
                            justify: "start"
                          }, {
                            default: _withCtx(function () {
                              return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_icon, {
                                class: "icon",
                                size: "50"
                              }, {
                                default: _withCtx(function () {
                                  return [_createVNode(_unref(UserFilled))];
                                }),
                                _: 1
                              })]), _createElementVNode("div", _hoisted_2, [_createElementVNode("h2", _hoisted_3, _toDisplayString(__props.user.fullName), 1)])];
                            }),
                            _: 1
                          })];
                        }),
                        _: 1
                      }), _createVNode(_component_el_col, {
                        span: 6
                      }, {
                        default: _withCtx(function () {
                          return [_createVNode(_component_el_row, {
                            align: "middle",
                            justify: "end"
                          }, {
                            default: _withCtx(function () {
                              return [_createVNode(_component_el_icon, {
                                class: "icon",
                                size: "50",
                                style: {
                                  cursor: 'pointer'
                                },
                                onClick: _cache[0] || (_cache[0] = function ($event) {
                                  return _unref(router).push({
                                    name: 'Profile'
                                  });
                                })
                              }, {
                                default: _withCtx(function () {
                                  return [_createVNode(_unref(Edit))];
                                }),
                                _: 1
                              })];
                            }),
                            _: 1
                          })];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  }), _createVNode(_component_el_row, {
                    align: "middle",
                    justify: "start",
                    class: "std-padding_line-md"
                  }, {
                    default: _withCtx(function () {
                      return [_createElementVNode("p", _hoisted_4, _toDisplayString(__props.user.email), 1)];
                    }),
                    _: 1
                  }), _createVNode(_component_el_row, {
                    align: "middle",
                    justify: "start",
                    class: "std-padding_line-md"
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_el_col, null, {
                        default: _withCtx(function () {
                          return [__props.user.pi_street ? (_openBlock(), _createBlock(_component_el_row, {
                            key: 0,
                            align: "middle",
                            justify: "start"
                          }, {
                            default: _withCtx(function () {
                              return [_createElementVNode("p", _hoisted_5, _toDisplayString(__props.user.pi_street), 1)];
                            }),
                            _: 1
                          })) : _createCommentVNode("", true), __props.user.pi_street2 ? (_openBlock(), _createBlock(_component_el_row, {
                            key: 1,
                            align: "middle",
                            justify: "start"
                          }, {
                            default: _withCtx(function () {
                              return [_createElementVNode("p", _hoisted_6, _toDisplayString(__props.user.pi_street2), 1)];
                            }),
                            _: 1
                          })) : _createCommentVNode("", true), __props.user.cityStatePostalLine ? (_openBlock(), _createBlock(_component_el_row, {
                            key: 2,
                            align: "middle",
                            justify: "start"
                          }, {
                            default: _withCtx(function () {
                              return [_createElementVNode("p", _hoisted_7, _toDisplayString(__props.user.cityStatePostalLine), 1)];
                            }),
                            _: 1
                          })) : _createCommentVNode("", true), __props.user.pi_country ? (_openBlock(), _createBlock(_component_el_row, {
                            key: 3,
                            align: "middle",
                            justify: "start"
                          }, {
                            default: _withCtx(function () {
                              return [_createElementVNode("p", _hoisted_8, _toDisplayString(__props.user.pi_country), 1)];
                            }),
                            _: 1
                          })) : _createCommentVNode("", true)];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  }), __props.ready && __props.user.shouldSeeAtuBanner ? (_openBlock(), _createBlock(_component_el_row, {
                    key: 0,
                    align: "middle",
                    justify: "center",
                    class: "std-padding_line-md"
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_AtuCtaBanner, {
                        showTrialBtn: !__props.user.wasTrialer
                      }, null, 8, ["showTrialBtn"])];
                    }),
                    _: 1
                  })) : __props.ready && __props.user.trialSub ? (_openBlock(), _createBlock(_component_el_row, {
                    key: 1,
                    align: "middle",
                    justify: "center",
                    class: "std-padding_line-md"
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_TrialBanner, {
                        trialSub: __props.user.trialSub
                      }, null, 8, ["trialSub"])];
                    }),
                    _: 1
                  })) : __props.ready && !(__props.user.shouldSeeAtuBanner || __props.user.trialSub) ? (_openBlock(), _createBlock(_component_el_row, {
                    key: 2,
                    align: "middle",
                    justify: "start",
                    class: "std-padding_line-md"
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_SubStatusBanner)];
                    }),
                    _: 1
                  })) : _createCommentVNode("", true)];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
};