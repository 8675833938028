import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  props: {
    trialSub: {
      'required': false,
      'default': {},
      'type': Object
    }
  },
  data: function data() {
    return {
      trialEndDate: null,
      interval: null,
      countdownTimer: null,
      trialProduct: '',
      now: new Date().getTime(),
      price: null,
      error: null,
      expiringSoon: false
    };
  },
  methods: {
    /**
     * Called by setInterval, updates the remaining time in the trial.
     */
    updateRemainingTime: function updateRemainingTime() {
      this.now = new Date().getTime();
      this.distance = this.trialEndDate.getTime() - this.now;

      if (this.distance < 0) {
        clearInterval(this.interval);
        this.interval = null;
        return;
      }

      var days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(this.distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));

      if (days <= 3) {
        this.expiringSoon = true;
      }

      this.setCountdownTimer(days, hours);
    },

    /**
     * Sets the countdown timer display. Updates every time updateRemainingTime() runs.
     */
    setCountdownTimer: function setCountdownTimer(days, hours) {
      var formatted = (hours < 10 ? '0' + hours.toString() : hours.toString()) + ' hours, ';

      if (days > 0) {
        formatted = ' in ' + days.toString() + ' days.';
      } else if (days <= 0) {
        formatted = 'today.';
      }

      this.countdownTimer = formatted;
    }
  },
  computed: {
    /**
     * Returns the trial end date, formatted according to the user's browser locale.
     */
    formattedTrialEndDate: function formattedTrialEndDate() {
      return this.trialEndDate.toLocaleDateString();
    }
  },
  mounted: function mounted() {
    var _this = this;

    if (this.distance > 0) {
      this.interval = setInterval(function () {
        return _this.updateRemainingTime();
      }, 1000);
    } else {
      this.interval = null;
    }
  },
  created: function created() {
    if (!(this.trialSub && this.trialSub.fs_data && this.trialSub.fs_data.nextChargeDate)) {
      this.error = 'Error loading your subscription details. Please try again later.';
      return;
    }

    this.trialEndDate = new Date(this.trialSub.fs_data.nextChargeDate);
    this.trialProduct = this.trialSub.sr_product || "Auto-Tune";
    this.distance = this.trialEndDate.getTime() - this.now;
    this.price = this.trialSub.fs_data.priceDisplay || "$24.99";
  }
};